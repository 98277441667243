const ZoneInMap = [
    {
        id:1,
        region:'Metropolitana',
        comuna:'Santiago',
        longitude:-70.6784828728591,
        latitude:-33.44837604728687
    },
    // {
    //     id:2,
    //     region:'Valparaiso',
    //     comuna:'Valparaiso',
    //     longitude:-71.61651342042937,
    //     latitude: -33.046482342483294,
    // },
  ]


  export default ZoneInMap;