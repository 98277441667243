export const realtorData = {
  email: 'all-estudio@unne.cl',
  phone:'56983615833'
  // email: 'contacto@accionpanal.com',
};

export const footerData = {
  email: 'all-estudio@unne.cl',
  phone:'983615833',
  wsp:'983615833',
}
